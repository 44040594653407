// Msal Configurations
export const config = {
  auth: {
    clientId: "fc0a5959-2621-45de-ae08-e94bc044a478",
    authority:
      "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
    redirectUri: "https://client-feedback-app.nwy.arup.com/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
