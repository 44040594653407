import React from "react";
import useStyles from "./login-page-styles";
import PT from "prop-types";

const Login = ({ login }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h2>Welcome to Client Feedback App</h2>
      <p>
        <span>Please login to continue!</span>
      </p>
      <button onClick={login} data-testid="login-button">
        Login
      </button>
    </div>
  );
};

export default Login;

Login.propTypes = { login: PT.func.isRequired };
