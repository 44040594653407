import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	loading: {
		height: "100vh",
		display: "grid",
		justifyItems: "center",
		alignItems: "center",
	},
}));
