import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./auth/authProvider";
import Routes from "./pages/Routes";
import Login from "./pages/login-page";
import LoadingPage from "./pages/loading-page";
import { createBrowserHistory } from "history";
import { Route, Router, Redirect } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const history = createBrowserHistory();

function App() {
	return (
		<Router history={history}>
			<AzureAD provider={authProvider}>
				{({ login, logout, authenticationState, error, accountInfo }) => {
					switch (authenticationState) {
						case AuthenticationState.Authenticated:
							return (
								<>
									<Suspense fallback={<LoadingPage />}>
										<Redirect to="/" />
										<Routes logout={logout} />
									</Suspense>
								</>
							);
						case AuthenticationState.Unauthenticated: {
							return (
								<>
									<Redirect to="/Login" />
									<Route exact path="/Login">
										<Login login={login} />
									</Route>
								</>
							);
						}
						case AuthenticationState.InProgress:
							return <p data-testid="login-progress">Login in progress</p>;
						default:
							return <div>logging in</div>;
					}
				}}
			</AzureAD>
		</Router>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register();
