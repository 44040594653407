import { CircularProgress } from "@material-ui/core/";
import useStyles from "./loading-page-styles";

const LoadingPage = () => {
	const classes = useStyles();
	return (
		<div className={classes.loading}>
			<CircularProgress />
		</div>
	);
}

export default LoadingPage;
