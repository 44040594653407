import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import PT from "prop-types";
const HomePage = lazy(() => import("./home-page"));
const AddClient = lazy(() => import("./add-client-page"));
const NewMeeting = lazy(() => import("./new-meeting-page"));
const QuestionPage = lazy(() => import("./question-page"));
const MeetingFrequency = lazy(() => import("./meeting-frequency-page"));
const ReviewMeeting = lazy(() => import("./review-page"));

function Routes({ logout }) {
	return (
		<Switch>
			<Route exact path="/" render={() => <HomePage logout={logout} />} />
			<Route exact path="/AddClient" render={() => <AddClient />} />
			<Route exact path="/NewMeeting" render={() => <NewMeeting />} />
			<Route exact path="/Questions" render={() => <QuestionPage />} />
			<Route
				exact
				path="/MeetingFrequency"
				render={() => <MeetingFrequency />}
			/>
			<Route exact path="/ReviewMeeting" render={() => <ReviewMeeting />} />
		</Switch>
	);
}

export default Routes;

Routes.propTypes = { logout: PT.func.isRequired };
