// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { config } from "./authConfig";

// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read"],
};

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
